iframe {
  display: none!important;
}

html{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0/var(--tw-bg-opacity));
}

body{
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    width: 100%;
    min-height: 100vh;
    color: #fff;
    line-height: 1;
    background: url(./asstes/img/bg.png) no-repeat top center;
    background-size: 100% 100%;
    background-position: top center;
    background-attachment: fixed;
}

.header-bg{
  background-image: url(./asstes/img/headerBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.textPage{
  *{
    font-family:Avenir;
  }
  h1{
    font-family: AvenirHeavy;
  }
  h2,strong{
    font-family: AvenirBlack;
  }
  h4{
    font-family:AvenirMedium;
  }

  &.prose :where(ol > li):not(:where([class~=not-prose] *))::marker {
    color: #fff;
    font-family: AvenirBlack;
    font-size: 1.5em;
  }
}


.hideScrollbar::-webkit-scrollbar {

  display:none
  
  }

  .border-linear{
    padding: 3px;
    background: linear-gradient(to right top , #FFDBA1, #3F8BFF);
    // clip-path: polygon(5% 0, 95% 0, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0 95%, 0 5%);
    // border-image: linear-gradient(to left , #FFDBA1, #3F8BFF) 1;
  }

  .tab-item{
    background: linear-gradient(rgba(0,73,138,0), rgba(13,60,111,24));
    border-bottom: 2px solid;
    border-color:  rgba(43,147,255,0.28);
  }

